$brand-primary: red;
$enable-gradients: true;
$enable-responsive-font-sizes: true;
$enable-rounded: true;
$brand-color: #102b46;
$primary: saturate(darken(#a53b3d, 5), 10);

// #102b46;
$brand-bg: theme-color("dark");
// #ffc107;
$bg-primary: lighen($primary, 20);
$font-family-base: "Ubuntu";
$font-size-base: 1rem;
$enable-shadows: true;
//$primary: theme-color-level(danger, -10);
// lighten($brand-color, 10);
$spacer: 0.8rem;
$rounded-corner: 5px;
//@import "bootstrap/scss/_variables.scss";

$theme-colors: (
  "primary": lighten($primary, 10),
  "secondary": #ffc107,
);

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// adjust-hue($color, $degrees)
// Changes the hue of a color.

// lighten($color, $amount)
// Makes a color lighter.

// darken($color, $amount)
// Makes a color darker.

// saturate($color, $amount)
// Makes a color more saturated.

// desaturate($color, $amount)
// Makes a color less saturated.

// grayscale($color)
// Converts a color to grayscale.

// complement($color)
// Returns the complement of a color.

// invert($color)
// Returns the inverse of a color.

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin default-card {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 2px;
  padding: 20px;

  background: linear-gradient(0deg, #dee2e6, #ffffff1c, #ffffff1c, #ffffff1c),
    linear-gradient(-45deg, #dee2e6, #ffffff1c, #ffffff1c);
  cursor: pointer;
  &:hover {
    background: linear-gradient(0deg, #ffcdd2, transparent);
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
}
