@import "./Variables.scss";
@import url("https://fonts.googleapis.com/css?family=Work+Sans|Jost|Roboto|Montserrat|Nova+Flat|Offside|Mina|Baloo+Bhai+2|Sriracha|Ubuntu");
@import "bootstrap/scss/bootstrap.scss";
body,
html,
#root {
  height: 100%;
}
body {
  position: relative;
}

.navbar {
  z-index: 10;
}

.main-modal-container {
  position: absolute;
  left: 50%;
  top: 50%;
  //transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  z-index: 2000;
}

.login-overlay {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: url("/assets/images/bg2.jpg");

  color: $brand-color;
  background-size: cover;
  padding: 20px;
}

.login-overlay .bussiness-title {
  position: absolute;
  font-size: 50px;
  font-weight: 900;
  color: $brand-bg;
  font-family: Work Sans;
  left: 20px;
  top: 20px;
}

.login-footer {
  background-color: #102b46a6;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100px;
  color: white;
  font-family: Jost;
  font-size: xx-large;
  font-weight: 900;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.login-container {
  background-color: rgba(22, 0, 0, 0.53);
  padding: 20px;
  color: white;
  border: 1px solid black;
}

.toaster-container {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2000;
  min-width: 300px;
  padding: 5px;
}

.bg-front {
  background: url("/assets/images/bg6.jpg") no-repeat $primary center;
  background-size: cover;
  height: 460px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-blend-mode: lighten;
}

.front-title {
  font-size: 5vw;
  font-weight: 900;

  color: white;
  text-shadow: 0 0 7px #3784d1;
}

.service-bg {
  background-color: #0a1e2108;
}

.call-us {
  background-color: $brand-bg;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar {
  width: 200px;
  height: 100%;
  display: inline-block;
  padding-top: 15px;

  position: fixed;
  z-index: 1400;
  transition: all 0.2s cubic-bezier(0.83, -0.18, 0, 1.16);
}
.sidebard-opened {
  left: 0 !important;
}
.panel-main-container {
  margin-left: 200px;
}

@media screen and (max-width: 425px) {
  .panel-main-container {
    padding-left: 10px;
    margin-left: 0;
  }
  .sidebar {
    left: -195px;
  }
  .sidebar:hover {
    left: 0;
  }
  .message-box {
    bottom: -285px !important ;
  }
}

.panel-main-container {
  display: inline-block;
  width: 100%;
  position: relative;
  background-color: #dfe0e2;
}

.page-title {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

@media screen and (max-width: 425px) {
  .call-us {
    font-size: 12px;
  }
}

.panel-container {
  display: flex;
  flex-direction: column;
}

.menuItem {
  padding-top: 7px;
  margin: 0;
  cursor: pointer;
  padding-left: 15px;
  transition: all 0.1s ease-in-out;
}
.menuItem:focus {
  background-color: $brand-bg;
}
.menuItem:hover {
  background: $brand-bg;
}
.submenu {
  display: none;
  transition: all 0.3s ease-in-out;
  padding-left: 0;
  border-top: 1px solid gray;
}
.submenu-item {
  padding: 3px;
  list-style: none;
  padding-left: 30px;
}
.submenu-item:hover {
  background-color: orange;
}
.menuItem:hover .submenu {
  display: block;
}

.input-seach {
  width: 250px !important;
}

.toast {
  -webkit-backdrop-filter: none !important;
  backdrop-filter: none !important;
  transform: translateX(200px);
  transition: all 0.15s cubic-bezier(0, 2.29, 0.58, 1);
}
.toast.notif.show {
  transform: translateX(0px) !important;
}
// .draggable {
//   /* just to size it to content */

//   display: flex;
//   justify-content: center;
//   align-items: center;
//   /* opaque background is important for performance */
//   background: white;
//   /* avoid selecting text while dragging */
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }
.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1501;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  padding: 100px;
  // overflow-y: scroll;
}

.bg-light-darker {
  background-color: #dfe0e2 !important;
}

.message-box {
  position: fixed;
  right: 10px;
  bottom: -255px;
  transition: all 0.3s ease-in-out;
  max-width: 300px;
}
.message-box > .toast-header {
  background-color: $brand-bg;
}

.message-box:hover {
  bottom: 0;
}

.toast-header.error {
  background-color: $danger;
  color: lighten($warning, 20);
  box-shadow: 0 0 5px 1px #ffc1076b !important;
}

.toast-header.success {
  background-color: $success;
  box-shadow: 0 0 5px 1px #ffc1076b;
  color: $white;
}
.toast-body.success {
  background-color: lighten($success, 10);
  box-shadow: inset 0 0 19px 0px #00ff2b47;
}

.toast-body.error {
  background-color: darken($danger, 4);
}
.toast-body.error {
  box-shadow: inset 0 0 19px 0px #ff000047 !important;
}

.toast-header.warning {
  background-color: $warning;
  //color: $warning;
}
.toast-body.warning {
  background-color: #ffc10730;
}

form > fieldset {
  font-weight: bold;
  font-size: x-large;
}

.default-form label {
  text-transform: capitalize;
  margin-top: 10px;
  margin-bottom: 2px;
}

.pointer {
  cursor: pointer;
}

.no-pointer {
  cursor: default !important;
}

.message-detail {
  min-height: 300px;
}

.form-control-file.is-invalid {
  border: 1px solid salmon !important;
}

.post-img {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  transition: all 0.3s ease-in-out;
  max-height: 200px;
  height: 70px;
}
.post-img:hover {
  transform: scale(1.2);
}
@media screen and (max-width: 425px) {
  .post-img {
    max-height: inherit;
    min-height: 200px;
    height: inherit;
  }
}

.days-title {
  width: 100%;
}

.user-info {
  right: 10px;
  background-color: $brand-bg;
  padding: 10px;
  float: right;
}

.allpage-title {
  float: left;
  margin: 0;
}

.user-menu {
  position: absolute;
  bottom: 0;
  background-color: red;
  width: 150px;
  top: 60px;
  right: 20px;
  height: 200px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  padding: 10px;
}

.user-menu.show {
  opacity: 1;
}

.not-found-model {
  position: absolute;
  width: 100%;
  top: 0;
}

.react-calendar {
  font-family: $font-family-base !important;
}

.react-date-picker__wrapper {
  border: none !important;
}

.ck-content {
  min-height: 250px;
}

.no-link:hover {
  text-decoration: none;
  color: inherit;
}
.main-news-body figure img {
  max-width: 100%;
}

.no-overrid {
  overflow: hidden;
}
.hover-scale {
  transition: all 0.3s ease-in-out;
}
.hover-scale:hover {
  transform: scale(1.4);
}

.navbar-center {
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 425px) {
  .navbar-center {
    position: absolute;
    left: 50%;
    margin-left: -26px;
  }

  .navbar-collapse {
    background-color: $primary;
    position: absolute;
    left: 0;
    width: 100%;
    top: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .front-title {
    font-size: 12vw;
  }
}

.service-item {
  padding-top: 2px;
}
.carousel {
  height: 60vh;
}
.carousel-inner {
  height: 100%;
}
.carousel-item-custom {
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  //transform: translateX(2000px);
  display: flex !important;
  justify-content: center;
  align-items: center;
  //transform: translateX(0px);
  opacity: 0;
  height: 100%;
  //animation: slide-out 1s ease-in-out 0 forwards;
}
.carousel-item-custom.active {
  //transform: translateX(0px);
  opacity: 1;
  //animation: slide-in 2s ease-in-out 0ms forwards;
}
@-webkit-keyframes slide-out {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(1500px);
    // z-index: -1;
    opacity: 0;
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(1500px);
    // z-index: -1;
    opacity: 0;
  }
}
@-webkit-keyframes slide-in {
  0% {
    transform: translateX(1500px);
    opacity: 0;
  }
  46% {
    transform: translateX(0px);
    opacity: 1;
  }
  80% {
    transform: translateX(0px);
    opacity: 1;
  }
  96% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    //   z-index: 0;
  }
}
@keyframes slide-in {
  0% {
    transform: translateX(1500px);
    opacity: 0;
  }
  46% {
    transform: translateX(0px);
    opacity: 1;
  }
  80% {
    transform: translateX(0px);
    opacity: 1;
  }
  96% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    //   z-index: 0;
  }
}
.carousel-item-custom img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.carousel-item-custom .title {
  position: absolute;

  bottom: 10vh;
  font-size: 8vw;
  text-transform: capitalize;
  background-color: #000000b0;
  color: white;
  padding-left: 1vw;
  padding-right: 1vw;
}
.carousel-item-custom .subtitle {
  position: absolute;
  bottom: 8vh;

  font-size: 2vw;
  background-color: #ffffff63;
  color: black;
  padding-left: 1vw;
  padding-right: 1vw;
}

.carousel-item-custom .subtitle.active {
  -webkit-animation: sub-title-in 0.3s cubic-bezier(0.11, 1.04, 0.31, 1.06)
    300ms forwards;
  animation: sub-title-in 0.3s cubic-bezier(0.11, 1.04, 0.31, 1.06) 300ms
    forwards;
}
.carousel-item-custom .title.active {
  -webkit-animation: title-in 0.2s cubic-bezier(0.18, 1.41, 1, 1) 300ms forwards;
  animation: title-in 0.2s cubic-bezier(0.18, 1.41, 1, 1) 300ms forwards;
}

@-webkit-keyframes title-in {
  0% {
    left: -200px;
    opacity: 0;
  }
  100% {
    left: 50px;
    opacity: 1;
  }
}

@keyframes title-in {
  0% {
    left: -200px;
    opacity: 0;
  }
  100% {
    left: 50px;
    opacity: 1;
  }
}

@-webkit-keyframes sub-title-in {
  0% {
    left: 800px;
    opacity: 0;
  }
  100% {
    left: 60px;
    opacity: 1;
  }
}

@keyframes sub-title-in {
  0% {
    left: 800px;
    opacity: 0;
  }
  100% {
    left: 60px;
    opacity: 1;
  }
}

.service-image {
  max-height: 100%;
  max-width: 100%;
}

.service-intro {
  height: 300px;
}

.service-bg {
  background-position: center !important;
  background-size: cover !important;
}

.service-detail {
  font-size: 1.2vw;
}

@media screen and (max-width: 425px) {
  .service-detail {
    font-size: 3vw;
  }
}

.form-group label {
  font-size: smaller;
  margin-bottom: 0;
  font-weight: 500;
  color: gray(0);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-top: 2px solid green;
}

.todo-done {
  text-decoration: line-through;
}

.upload-image-preview {
  max-height: 300px;
}
.file-container {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-color: $light;
}
.file-operation {
  display: flex;
  background-color: #0000007d;
  position: absolute;
  left: 0;
  bottom: -100px;
  width: 100%;
  padding: 10px;
  align-items: flex-end;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  border-top: 1px solid black;
}
.file-container:hover {
  //transform: scale(1.2);
  z-index: 5;
}
.file-container img {
  transition: all 0.2s ease-in-out;
}
.file-container:hover img {
  -o-object-fit: contain;
  object-fit: contain;
}
.file-container:hover .file-operation {
  bottom: 0;
  //transform: scale(0.6);
}

.full-view-file {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000ce;
  align-items: center;
  justify-content: center;
  z-index: 1506;
}

.upload-preview {
  height: 120px;
}
.upload-preview > figcaption {
  position: absolute;
  color: white;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.651);
  z-index: 1;
}
.upload-preview > img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.upload-image-preview {
  // position: absolute;
  width: 150px;
  height: 150px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  transition: all 0.3s ease-in-out;
}
.upload-image-preview:hover {
  transform: scale(1.5);
  z-index: 100;
}

figure {
  max-width: 80%;
}

.table thead th,
form label {
  font-size: smaller;
  margin-top: 0.5rem;
  margin-bottom: inherit;
}

.table-sm th,
.table-sm td {
  font-size: small;
  vertical-align: middle;
}

.menuItem {
  border-bottom: 1px solid #fafafa12;
  padding-bottom: 7px;
  margin-left: 7px;
  margin-right: 7px;
}

.table-sm th {
  background-color: #f5f5f5;
}

.pagination {
  margin: 0;
}

.ReactCrop {
  display: block !important;
}

.ReactCrop img {
  width: 100%;
}

.full-view-file figure {
  height: 90%;
}
.full-view-file figure img {
  max-height: 90%;
}

.capitalize {
  text-transform: capitalize;
}
.tooltip-container {
  position: relative;
}
.tooltip {
  transition: all 0.3s ease-in-out;
  top: -30px;
}
.tooltip-container:hover .tooltip {
  opacity: 1;
  top: -30px;
  background-color: #000000ba;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 10px;
  color: white;
}

.tooltip:after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* 
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
  position: relative;
  z-index: 10;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px);
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: -webkit-max-content;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  /*   font-size: 0.73rem; */
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(
    30deg,
    rgba(59, 72, 80, 0.44),
    rgba(59, 68, 75, 0.44),
    rgba(60, 82, 88, 0.44)
  );
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -5px) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: "";
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
  transform-origin: top; /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}
/* Slide down effect only on mouseenter (NOT on mouseleave) */
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
/*
  That's it.
*/

/*
  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
  transform: translate(-5px, 50%) scale(0.5);
}
[data-tooltip-location="left"]:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(100% + 5px);
  bottom: 50%;
}

[data-tooltip-location="right"]:before {
  transform: translate(5px, 50%) scale(0.5);
}
[data-tooltip-location="right"]:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[data-tooltip-location="right"]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[data-tooltip-location="bottom"]:before {
  transform: translate(-50%, 5px) scale(0.5);
}
[data-tooltip-location="bottom"]:hover:before {
  transform: translate(-50%, 5px) scale(1);
}

[data-tooltip-location="bottom"]:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}

.dropdown-menu {
  min-width: 7.5rem;
  font-size: 0.7rem;
  top: 90%;
}
.dropdown-item {
  cursor: pointer;
}

.dropdown-item:hover {
  background: rgba(128, 128, 128, 0.568);
}

.trt {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  //background-color: green;
  min-height: 250px;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  position: relative;
}
.trt:after {
  content: "-";
  position: absolute;
  width: 100%;
  height: 20px;
  background-color: #ffeb3b78;
}
.trt li {
  display: inline-block;
  background-color: greenyellow;
  width: 20px;
  height: 20px;
  border-radius: 25px;
  position: relative;
  font-size: 10px;
  font-weight: bold;
}

.trt li:nth-child(odd):before {
  content: attr(data-year);
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%);
}

.trt li:nth-child(even):before {
  content: attr(data-year);
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translate(-50%);
}

.trt li:after {
  content: attr(data-text);
  display: inline-block;
  position: absolute;
  //width: 200px;
}

.trt li:nth-child(odd):after {
  top: 30px;
  //width: 200px;
}

.trt li:nth-child(even):after {
  bottom: 30px;
  //width: 200px;
}

.border-grey {
  border-color: #6c757d61 !important;
}

.input-group .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
